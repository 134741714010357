.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #000000e6;
	z-index: 2;
	height: 100vh;
	animation:fadein .5s;
}

@keyframes fadein {
	from {
		opacity:0;
	}
	to {
		opacity:1;
	}
}

.modal-overlay-wrp {
	position: absolute; 
	top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	background-color: var(--bs-gray-900);
	padding: 25px;
	border-radius: 2px;
  border: 1px solid rgb(255 255 255 / 5%);
	max-height: 50vh;
  overflow: auto;
}

.overlay-btn-style {
	border: 1px solid var(--bs-primary);
	background-color: transparent;
	background: transparent;
	cursor: pointer;
	padding: 11px 40px;
	border-radius: 5px;
	color: white;
	font-weight: 600;
}

.overlay-btn-style:hover {
	background-color: #ffffff0d;
	background: #ffffff0d;
}

input[type='range']::-moz-range-track {
	background-color: #ffffff;
	height: 4px;
	border-radius: 3px;
}

.overlay-text-style {
	font-weight: 600;
	color: white;
	margin-bottom: 40px;
	margin-top: 40px;
}

@media (max-width: 812px) { 
	.modal-overlay-wrp {
		margin-left: 15px;
		margin-right: 15px;
	}
}
