.comunity-sale-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}

.loader-wrapper {
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
}

.faqs-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.video-wrapper {
	position: absolute;
}

.border-v-line-right {
	border-right: 1px solid gray;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
