
.navbar-container-web {
	width: 100%;
	display: flex;
	align-items: center;
	padding-left: 35px;
	padding-right: 35px;
	height: 80px;
	-webkit-box-shadow: inset 0px 49px 30px 0px rgba(0,0,0,0.45); 
	box-shadow: inset 0px 49px 30px 0px rgba(0,0,0,0.45);
	position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 5;
}

.navbar-wrp {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	width: 100%;
}

.left-side-wrp {
	display: flex;
	align-items: center;
	justify-content: center;
}

.burguer-logo {
	width: 35px;
	height: 35px;
	margin-right: 35px;
	cursor: pointer;
	border-radius: 0;
}

.navbar-logo-gamium {
	height: 15px;
	cursor: pointer;
}

.nav-links-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
}

.nav-link {
	color: #cccccc;
	font-size: 12pt;
	cursor: pointer;
	padding: 0px;
	font-weight: bold;
}

.nav-link:hover {
	color: #ffffff;
}

.mr-35 {
	margin-right: 35px;
}


.btn-with-logo{
	margin-right: 35px;
	display: flex;
	align-items: center;
	gap: 5px;
}

.icon-open-external{
	width: 10px;
}

#dropdown-autoclose-true	{
	box-shadow: unset;
	background-color: unset;
	border-color: unset;
}

.wrapper-dapps{
	display: flex;
	align-items: center;
	color: #cccccc;
	font-weight: bold;
	font-size: 12pt;
	background-color: unset;
	border: unset;
	padding: 0px;
	gap: 5px;
}

.wrapper-dapps:hover{
	background-color: unset;
	border: unset;
}

.caret-down-navbar{
	width: 5px;
}

.btn-list-dapps{
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 40px;
	background-color: #161616;
	padding: 0px 10px;
	color: #cccccc;
	cursor: pointer;
	font-size: 12pt;
	font-weight: bold;
}

.btn-list-dapps:hover {
	background-color: #1c1c1c;
	color: white;
}

.navbar-container-mobile{
	display: none;
	position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 5;
	-webkit-box-shadow: inset 0px 49px 30px 10px rgba(0,0,0,0.45); 
	box-shadow: inset 0px 49px 30px 10px rgba(0,0,0,0.45);
}

a {
	text-decoration: none;
}

#navId .active {
	color: #ffffff ;
}

/*#navId a {
	color: #cccccc;
	text-decoration: none;
}*/

#navId a:hover,
a:focus {
	color: #ffffff ;
}

.navbar-mobile {
	width: 100%;
	align-items: center;
	padding-left: 35px;
	padding-right: 35px;
	height: 80px;
}

.navbar-mobile-top{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.navbar-mobile-down {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 35px 25px 35px;
}

.nav-link-mobile{
	color: #cccccc;
	font-size: 12pt;
	padding: 0px;
	font-weight: bold;
	cursor: pointer;
	margin-right: 35px;
	display: flex;
	align-items: center;
	gap: 5px;
}

.nav-link-mobile:hover{
	color: white;
}

.nav-link-mobile-active{
	color: white;
	font-size: 12pt;
	padding: 0px;
	font-weight: bold;
	cursor: pointer;
	margin-right: 35px;
}

.pr-25 {
	padding: 0px 25px 0px 0px ;
}


.ml-25 {
	margin-left: 25px;
}

.nav-link-menu {
	color: #cccccc;
	font-size: 12pt;
	cursor: pointer;
	margin-right: 35px;
	padding: 0px;
	display: block;
}


.nav-link-menu:hover {
	color: white;
}


.mr-35{
	margin-right: 35px;
}


.navbar-wallet-btn-img {
	width: 20px;
	margin-left: 20px;
}


.btn-connect-span {
	font-size: 12pt;
	color: white;
}

.spacer-vertical-line {
	margin-right: 35px;
	width: 2px;
	height: 15px;
	background-color: var(--bs-gray);
}

.btn-launch-app{
	color: #cccccc;
	font-size: 12pt;
	width: 140px;
	font-weight: bold;
	white-space: nowrap;
}

.caret-down-wrapper{
	background-color: rgba(0, 0, 0, 0.90);
	border-color: rgba(0, 0, 0, 0.90);
	border-radius: 10px;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin: 0px;
	padding: 0px;
}

.caret-down-wrapper:hover{
	background-color: rgba(0, 0, 0, 0.4);
}

.icon-arrow-down-text{
	width: 5px;
}


.dropdown-menu{
	padding: 0px;
	transform: translate(0px, 40px) !important;
	width: 100%;
  border: 1px solid #471E96;
	background-color: transparent;
	text-align: center;
}

.dropdown-toggle::after{
	display: none;
}

.dropdown-toggle-connect{
	min-width: 160px;
}

.dropdown-toggle-connect::after {
	display: none;
}

.button-connect-wallet{
	color: #cccccc;
	min-width: 160px;
}

#dropdown-navbar{
	transform: translate(-100px, 30px) !important;
	border: 0px;
}

.dropdown-address{
	font-size: 14px;
	color: var(--bs-white);
	display: flex;
	justify-content: space-between;
	/* &.Empty span {
		color: var(--bs-gray-600);
	} */
}

.dropdown-provider-logo{
	width: 20px;
	margin-left: 20px;
}

.dropdown-menu-connect {
	border: 1px solid var(--bs-primary);
	font-size: 14px;
	width: 100%;
	min-width: auto;
	text-align: center;
	background-color: var(--bs-black);
	padding: 0px;	
	transform: translate(0px, 38px) !important;
}

.dropdown-item {
	color: var(--bs-white);
}

.dropdown-item:hover {
	color: var(--bs-black);
	background-color: var(--bs-primary);
}

.dropdown-item:focus {
	color: var(--bs-black);
	background-color: var(--bs-primary);
}

.active{
	color :white;
}

.connect-wallet-btn{
	font-weight: 300;
	width: 170px;
	color: #ccc;
    min-width: 160px;
}


@media (max-width: 900px) { 
  .navbar-container-web{
		display: none;
	}

	.navbar-container-mobile{
		display: flex;
		flex-direction: column;
	}
}

@media (max-width: 500px) { 

	.navbar-mobile-down {
		justify-content: space-between;
	}
	
	.nav-link-mobile, .nav-link-mobile-active{
		margin-right: 0px;
	}

	.navbar-logo-gamium{
		display: none;
	}

	.dropdown-menu{
		padding: 0px;
		transform: translate(0px, 30px) !important;
	}	
	
	.wrapper-dapps , .nav-link-mobile , .nav-link-mobile-active, .btn-list-dapps{
		font-size: 10pt;
	}
	
}

@media (max-width: 370px) { 

	.navbar-mobile-down{
		padding: 0px 10px 25px 10px;
	}
	
}

