.loader {
	margin: auto;
	border: 2px dotted var(--bs-primary);
	border-top: 2px solid var(--bs-primary);
	border-radius: 50%;
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
	width: 25px;
	height: 25px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.loader-wpr{
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
}

